<template>
  <div class="list-view product-checkout mt-0">

    <!-- Products List -->
    <checkout-step-products :products="products" @removeProduct="removeProduct" @updateProductTotal="calculatePrices" />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>

        <label class="section-label mb-1">Options</label>
        <b-input-group class="input-group-merge coupons">
          <b-form-input placeholder="Coupons" />
          <b-input-group-append is-text>
            <span
              id="input-coupons"
              class="input-group-text text-primary cursor-pointer"
            >Apply</span>
          </b-input-group-append>
        </b-input-group>
        <hr>
        <div class="price-details">
          <h6 class="price-title">
            Price Details
          </h6>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                Total Amount
              </div>
              <div class="detail-amt">
                ${{totalDetails.total}}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Bag Discount
              </div>
              <div class="detail-amt discount-amt text-success">
                -{{totalDetails.discount}}$
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Estimated Tax
              </div>
              <div class="detail-amt">
                ${{totalDetails.tax}}
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                Total
              </div>
              <div class="detail-amt font-weight-bolder">
                ${{totalDetails.subTotal}}
              </div>
            </li>
          </ul>
          <b-button
            variant="primary"
            block
            @click="$emit('next-step')"
          >
            Place Order
          </b-button>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import CheckoutStepProducts from './CheckoutStepProducts.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,

    // SFC
    CheckoutStepProducts,
  },
  props: {
    totalDetails: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  updated() {
    this.calculatePrices(this.products)
  },
  methods: {
    calculatePrices(products) {
      return this.$emit('calculatePrices', products)
    },
    calculateTotal(products) {
      return this.$emit('calculateTotal', products)
    },
    calculateTotalQty(products) {
      return this.$emit('calculateTotalQty', products)
    },
    calculateDiscounts(products) {
      return this.$emit('calculateDiscounts', products)
    },
    removeProduct(product) {
      this.$emit('removeProduct', product)
      this.$emit('calculatePrices', product)
    },
  },
}
</script>
