<template>

  <div class="checkout-items">
    <b-card
      v-for="(product, index) in products"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <b-link>
          <b-img
            src="../../../assets/images/pages/bank.png"
            :alt="`${product.name}-${product.id}`"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ product.name }} ( {{ product.buildText }} )
            </b-link>
          </h6>
          <span class="item-company"> {{ product.message }}</span>
        </div>
        <div class="item-quantity">
          <span class="quantity-title">Qty:</span>
          <b-form-spinbutton
            v-model="product.qty"
            size="sm"
            class="ml-75"
            inline
            @change="updateQty(index)"
          />
        </div>
        <br>
        <span class="text-success">{{ product.discountPercentage }}% off {{ product.offers }} {{ product.offerQtyLimit }} {{ product.offerItem }}</span>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              ${{ product.unitPrice }}
            </h4>
            <p
              v-if="product.subscription"
              class="card-text shipping"
            >
              <b-badge
                pill
                variant="light-warning"
              >
                Subscription
              </b-badge>
            </p>
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProduct(product)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Remove</span>
        </b-button>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
} from 'bootstrap-vue'
// import store from '@/store'
// import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
  },
  setup() {
    return {
      // Filter
      formatDate,
    }
  },
  props: {
    products: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    updateQty(index) {
      this.products[index].price = this.getProductCost(this.products[index])
      this.$emit('updateProductTotal', this.products)
      const sellingProduct = {
        title: this.products[index].name,
        qty: this.products[index].qty,
      }
      this.$store.dispatch('checkout/updateSellingProductQty', sellingProduct)
    },
    getProductCost(product) {
      return (Math.round(product.unitPrice * product.qty * 100) / 100)
    },
    removeProduct(product) {
      this.$emit('removeProduct', product)
    },
  },
}
</script>

<style>

</style>
