<template>
  <div class="list-view product-checkout">

    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Payment Options</b-card-title>
        <b-card-text class="text-muted mt-25">
          Be sure to click on correct payment option
        </b-card-text>
      </b-card-header>
      <b-card-body>

        <!-- Radios -->
        <b-form-group>
          <b-form-radio
            name="payment-method"
            value="paypal"
            checked="true"
            @change="payByPaypal($event)"
          >
            PayPal
          </b-form-radio>
          <b-form-radio
            name="payment-method"
            class="mt-1"
            value="stripe"
            @change="payByStripe($event)"
          >
            Stripe
          </b-form-radio>
          <b-form-radio
            name="payment-method"
            value="coupon"
            class="mt-1"
            @change="payByCoupon($event)"
          >
            Coupon Code
          </b-form-radio>
          <div
            v-if="showCouponEnter"
            class="d-flex flex-wrap align-items-center justify-content-start my-1"
          >
            <b-form-input
              id="coupon"
              v-model="coupon"
              class="mr-1 mb-1"
              trim
            />
            <b-button
              variant="primary"
              class="mb-1"
              @click="applyCoupon()"
            >
              Apply
            </b-button>
          </div>

          <hr class="mb-2 mt-1">
        </b-form-group>

        <hr class="my-2">

        <p class="cursor-pointer">
          <feather-icon
            icon="PlusCircleIcon"
            size="21"
            class="mr-75"
          />
          <span class="align-middle">Add Gift Card</span>
        </p>
      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Price Details">

        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Price of {{ totalDetails.totalQty }} items
            </div>
            <div class="detail-amt">
              <strong>${{ totalDetails.subTotal }}</strong>
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Processing Fees
            </div>
            <div class="detail-amt discount-amt text-success">
              Free
            </div>
          </li>
        </ul>
        <hr>
        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Amount Payable
            </div>
            <div class="detail-amt font-weight-bolder">
              ${{ totalDetails.subTotal }}
            </div>
          </li>
        </ul>
        <ul>
          <div
            v-show="showPaypal"
            ref="paypal"
          />
          <b-button
            v-show="showCoupon"
            variant="primary"
            block
            @click="processCouponPayment()"
          >
            Pay With Coupon
          </b-button>
          <div v-show="showStripe">
            <stripe-checkout
              ref="checkoutRef"
              mode="payment"
              :pk="publishableKey"
              :client-reference-id="orderId"
              :line-items="stripeLineItems"
              :success-url="successURL"
              :cancel-url="cancelURL"
              @loading="v => loading = v"
            />
            <b-button
              variant="primary"
              block
              @click="processStripePayment()"
            >
              Pay With Stripe
            </b-button>
          </div>
        </ul>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BFormGroup, BFormRadio, BFormInput, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { StripeCheckout } from '@vue-stripe/vue-stripe'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    StripeCheckout,
  },
  props: {
    totalDetails: {
      type: Object,
      required: true,
    },
    stripeLineItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      orderId: '',
      loaded: false,
      paidFor: false,
      showPaypal: true,
      showStripe: false,
      showCouponEnter: false,
      showCoupon: false,
      coupon: '',
      product: {
        price: 10.00,
        description: 'Project Payments',
        img: './assets/lamp.jpg',
      },
      payment: {
        created_time: '',
        updated_time: '',
        id: '',
        status: '',
        payer_email: '',
        payer_id: '',
      },
      builds: {
        basic: 0,
        standard: 0,
        premium: 0,
        basicSubscription: 0,
        standardSubscription: 0,
        premiumSubscription: 0,
      },
      successURL: '',
      cancelURL: '',
    }
  },
  mounted() {
    const script = document.createElement('script')
    // Create new paypal app with website name and get an live client id
    script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLINT_ID}`
    script.addEventListener('load', this.setLoaded)
    // script.addEventListener('load', this.createNewOrder)
    document.body.appendChild(script)
    this.$store.dispatch('checkout/getNewOrderId').then(id => { this.orderId = id })
  },
  methods: {
    processCouponPayment() {
      console.log('process')
    },
    processStripePayment() {
      const code = this.randomCode(25)
      this.successURL = `${process.env.VUE_APP_SITE_BASE_URL}/checkout?status=success&method=stripe&order=${this.orderId}&signature=${code}`
      this.cancelURL = `${process.env.VUE_APP_SITE_BASE_URL}/checkout?status=cancelled&method=stripe&order=${this.orderId}`
      this.$emit('createLocalOrderSignature', `${this.orderId},${code}`)
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout()
    },
    applyCoupon() {
      console.log(this.coupon)
    },
    payByPaypal() {
      this.showPaypal = true
      this.showStripe = false
      this.showCoupon = false
      this.showCouponEnter = false
    },
    payByStripe() {
      this.showPaypal = false
      this.showStripe = true
      this.showCoupon = false
      this.showCouponEnter = false
    },
    payByCoupon() {
      this.showPaypal = false
      this.showStripe = false
      this.showCoupon = true
      this.showCouponEnter = true
    },
    setLoaded() {
      this.loaded = true
      window.paypal
        .Buttons({
          createOrder: (data, actions) => actions.order.create({
            purchase_units: [
              {
                invoice_id: this.orderId,
                reference_id: `ref:${this.orderId}`,
                description: this.product.description,
                amount: {
                  currency_code: 'USD',
                  value: this.totalDetails.subTotal,
                },
              },
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING',
            },
          }),
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()
            this.paidFor = true
            this.payment.created_time = order.created_time
            this.payment.updated_time = order.updated_time
            this.payment.id = order.id
            this.payment.status = order.status
            this.payment.payer_email = order.payer.email_address
            this.payment.payer_id = order.payer.payer_id
            this.createNewOrder('PayPal', 'Success', order.id)
            this.updateBuilds()
          },
          onError: err => {
            console.log(err)
            this.createNewOrder('PayPal', 'Failed', '')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Payment Failed! Please try again.',
                icon: 'FrownIcon',
                variant: 'danger',
              },
            })
          },
        })
        .render(this.$refs.paypal)
    },
    createNewOrder(paymentMethod, paymentStatus, paymentRef) {
      this.$store.dispatch('checkout/createNewOrder', {
        id: this.orderId, subtotal: this.totalDetails.total, total: this.totalDetails.subTotal, discount: this.totalDetails.discount, products: this.totalDetails.products, method: paymentMethod, status: paymentStatus, ref: paymentRef,
      }).then(id => {
        console.log(id)
      })
    },
    updateBuilds() {
      this.$store.dispatch('checkout/getSellingProduct').then(res => {
        this.builds.basicSubscription = 0
        this.builds.standardSubscription = 0
        this.builds.premiumSubscription = 0
        this.totalDetails.products = []
        for (let i = 0; i < res.length; i += 1) {
          const product = {
            name: res[i].title,
            qty: res[i].qty,
            buildText: '3 Builds',
          }
          if (res[i].title.includes('Subscription')) {
            product.buildText = 'Unlimited Builds'
          }
          this.totalDetails.products.push(`${product.name} (${product.buildText}) x ${product.qty}`)
          const packageType = product.name.toLowerCase()
          if (packageType === 'basic') {
            this.builds.basic = product.qty
          } else if (packageType === 'basic subscription') {
            this.builds.basicSubscription = product.qty
          } else if (packageType === 'standard') {
            this.builds.standard = product.qty
          } else if (packageType === 'standard subscription') {
            this.builds.standardSubscription = product.qty
          } else if (packageType === 'premium') {
            this.builds.premium = product.qty
          } else if (packageType === 'premium subscription') {
            this.builds.premiumSubscription = product.qty
          }
        }
        this.$store.dispatch('checkout/updateUserBuilds', this.builds).then(id => {
          console.log(id)
          this.clearCart()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Payment Successful! Builds added.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'home' })
        })
      })
    },
    clearCart() {
      this.$store.dispatch('checkout/clearAllSellingProducts')
    },
    randomCode(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
