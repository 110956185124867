<template>
  <form-wizard
    ref="refFormWizard"
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- account detail tab -->
    <tab-content
      title="Packages"
      icon="feather icon-shopping-cart"
    >
      <checkout-step-cart
        :total-details="totalDetails"
        :products="products"
        @next-step="formWizardNextStep"
        @calculatePrices="calculatePrices"
        @calculateTotal="calculateTotal"
        @calculateTotalQty="calculateTotalQty"
        @calculateDiscounts="calculateDiscounts"
        @removeProduct="removeProduct"
      />
    </tab-content>

    <!-- address -->
    <tab-content
      title="Confirmation"
      icon="feather icon-home"
    >
      <checkout-step-confirm
        :total-details="totalDetails"
        :products="products"
        @next-step="formWizardNextStep"
      />
    </tab-content>

    <!-- social link -->
    <tab-content
      title="Payment"
      icon="feather icon-credit-card"
    >
      <checkout-step-payment
        :total-details="totalDetails"
        :stripe-line-items="stripeLineItems"
        @next-step="formWizardNextStep"
        @createLocalOrderSignature="createLocalOrderSignature"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CheckoutStepPayment from './CheckoutPayment.vue'
import CheckoutStepCart from './CheckoutStepCart.vue'
import CheckoutStepConfirm from './CheckoutStepConfirm.vue'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,

    // SFC
    CheckoutStepPayment,
    CheckoutStepCart,
    CheckoutStepConfirm,
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    return {
      refFormWizard,
      formWizardNextStep,
    }
  },
  data() {
    return {
      totalDetails: {
        total: 0,
        discount: 0,
        tax: 0,
        subTotal: 0,
        totalQty: 0,
        products: [],
        builds: {
          basic: 0,
          standard: 0,
          premium: 0,
          basicSubscription: 0,
          standardSubscription: 0,
          premiumSubscription: 0,
        },
      },
      stripeLineItems: [],
      builds: {
        basic: 0,
        standard: 0,
        premium: 0,
        basicSubscription: 0,
        standardSubscription: 0,
        premiumSubscription: 0,
      },
      products: [],
      promotions: {
        buildBased: {
          builds: 3,
          discountPercentage: 25,
          offers: 'when you are buying more than',
          offerItem: 'builds',
          offerQtyLimit: 10,
        },
        subscriptionBased: {
          builds: 0,
          discountPercentage: 25,
          offers: 'when you are subscribing for more than',
          offerItem: 'months',
          offerQtyLimit: 3,
        },
      },
      pricing: {
        basicPlan: {
          img: 'https://webtoappconvert.web.app/img/Pot1.svg',
          title: 'Basic',
          subtitle: 'A simple start for everyone',
          buildPrice: '4.99',
          monthlyPlan: {
            perMonth: '29.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: false },
            { message: 'Push Notifications', available: false },
            { message: 'AdMob support', available: false },
            { message: 'File download', available: false },
            { message: 'File upload', available: false },
            { message: 'Enable caching', available: false },
            { message: 'Support deep linking', available: false },
            { message: 'Preserve cookies', available: false },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds'],
        },
        standardPlan: {
          popular: true,
          img: 'https://webtoappconvert.web.app/img/Pot2.svg',
          title: 'Standard',
          subtitle: 'Pack of most essential features',
          buildPrice: '9.99',
          monthlyPlan: {
            perMonth: '99.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: true },
            { message: 'Push Notifications', available: true },
            { message: 'AdMob support', available: false },
            { message: 'File download', available: true },
            { message: 'File upload', available: false },
            { message: 'Enable caching', available: true },
            { message: 'Support deep linking', available: false },
            { message: 'Preserve cookies', available: false },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds', 'Unlimited Standard builds'],
        },
        enterprisePlan: {
          popular: true,
          img: 'https://webtoappconvert.web.app/img/Pot3.svg',
          title: 'Premium',
          subtitle: 'With all the features you can imagine',
          buildPrice: '14.99',
          monthlyPlan: {
            perMonth: '149.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: true },
            { message: 'Push Notifications', available: true },
            { message: 'AdMob support', available: true },
            { message: 'File download', available: true },
            { message: 'File upload', available: true },
            { message: 'Enable caching', available: true },
            { message: 'Support deep linking', available: true },
            { message: 'Preserve cookies', available: true },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds', 'Unlimited Standard builds', 'Unlimited Premium builds'],
        },
        qandA: [
          {
            question: 'test',
            ans: 'ans',
          },
        ],

      },
      order_id: '',
      signature_code: '',
    }
  },
  updated() {

  },
  mounted() {
    this.addProduct(this.$route.query.product)
    this.loadSellingProduct()
    this.calculatePrices(this.products)
    if (this.$route.query.status === 'cancelled') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Payment Cancelled !',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      this.createNewOrder('Stripe', 'Cancelled', this.$route.query.order)
    }
    if (this.$route.query.status === 'success') {
      this.$store.dispatch('checkout/validatePaymentSignature', `${this.$route.query.order},${this.$route.query.signature}`).then(res => {
        if (res) {
          this.order_id = this.$route.query.orde
          this.$store.dispatch('checkout/clearPaymentSignature')
          this.createNewOrder('Stripe', 'Success', this.$route.query.order)
          this.updateBuilds()
        } else {
          this.$router.push({ name: 'dashboard' })
        }
      })
    }
  },
  methods: {
    loadSellingProduct() {
      this.products = []
      this.$store.dispatch('checkout/getSellingProduct').then(res => {
        for (let i = 0; i < res.length; i += 1) {
          const product = {
            id: res[i].title,
            name: res[i].title,
            image: null,
            message: res[i].subtitle,
            qty: res[i].qty,
            builds: 3,
            buildText: '3 Builds',
            discountPercentage: this.promotions.buildBased.discountPercentage,
            offers: this.promotions.buildBased.offers,
            offerItem: this.promotions.buildBased.offerItem,
            offerQtyLimit: this.promotions.buildBased.offerQtyLimit,
            unitPrice: res[i].price,
            price: (res[i].price * res[i].qty),
            subscription: false,
          }
          if (res[i].title.includes('Subscription')) {
            product.buildText = 'Unlimited Builds'
            product.discountPercentage = this.promotions.subscriptionBased.discountPercentage
            product.offers = this.promotions.subscriptionBased.offers
            product.offerItem = this.promotions.subscriptionBased.offerItem
            product.offerQtyLimit = this.promotions.subscriptionBased.offerQtyLimit
            product.subscription = true
          }
          this.totalDetails.products.push(`${product.name} (${product.buildText}) x ${product.qty}`)
          const packageType = res[i].title.toLowerCase()
          if (packageType === 'basic') {
            this.totalDetails.builds.basic = res[i].qty
            product.stripe_product = process.env.VUE_APP_STRIPE_BASIC_PRODUCT
            product.stripe_product_discounted = process.env.VUE_APP_STRIPE_BASIC_PRODUCT_DISCOUNTED
          } else if (packageType === 'basic subscription') {
            this.totalDetails.builds.basicSubscription = res[i].qty
            product.stripe_product = process.env.VUE_APP_STRIPE_BASIC_SUBSCRIPTION_PRODUCT
            product.stripe_product_discounted = process.env.VUE_APP_STRIPE_BASIC_SUBSCRIPTION_PRODUCT_DISCOUNTED
          } else if (packageType === 'standard') {
            this.totalDetails.builds.standard = res[i].qty
            product.stripe_product = process.env.VUE_APP_STRIPE_STANDARD_PRODUCT
            product.stripe_product_discounted = process.env.VUE_APP_STRIPE_STANDARD_PRODUCT_DISCOUNTED
          } else if (packageType === 'standard_subscription') {
            this.totalDetails.builds.standardSubscription = res[i].qty
            product.stripe_product = process.env.VUE_APP_STRIPE_STANDARD_SUBSCRIPTION_PRODUCT
            product.stripe_product_discounted = process.env.VUE_APP_STRIPE_STANDARD_SUBSCRIPTION_PRODUCT_DISCOUNTED
          } else if (packageType === 'premium') {
            this.totalDetails.builds.premium = res[i].qty
            product.stripe_product = process.env.VUE_APP_STRIPE_PREMIUM_PRODUCT
            product.stripe_product_discounted = process.env.VUE_APP_STRIPE_PREMIUM_PRODUCT_DISCOUNTED
          } else if (packageType === 'premium_subscription') {
            this.totalDetails.builds.premiumSubscription = res[i].qty
            product.stripe_product = process.env.VUE_APP_STRIPE_PREMIUM_SUBSCRIPTION_PRODUCT
            product.stripe_product_discounted = process.env.VUE_APP_STRIPE_PREMIUM_SUBSCRIPTION_PRODUCT_DISCOUNTED
          }
          this.products.push(product)
        }
      })
    },
    removeProduct(product) {
      const sellingProduct = {
        title: product.name,
      }
      this.$store.dispatch('checkout/removeSellingProduct', sellingProduct)
      this.loadSellingProduct()
    },
    addProduct(product) {
      const item = product ? product.toLowerCase() : ''
      if (item === '') {
        return
      }
      const sellingProduct = {
        title: '',
        subtitle: '',
        price: '0.0',
        qty: 1,
      }
      if (item === 'basic') {
        sellingProduct.title = this.pricing.basicPlan.title
        sellingProduct.subtitle = this.pricing.basicPlan.subtitle
        sellingProduct.price = this.pricing.basicPlan.buildPrice
      } else if (item === 'basic_subscription') {
        sellingProduct.title = `${this.pricing.basicPlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.basicPlan.subtitle
        sellingProduct.price = this.pricing.basicPlan.monthlyPlan.perMonth
      } else if (item === 'standard') {
        sellingProduct.title = this.pricing.standardPlan.title
        sellingProduct.subtitle = this.pricing.standardPlan.subtitle
        sellingProduct.price = this.pricing.standardPlan.buildPrice
      } else if (item === 'standard_subscription') {
        sellingProduct.title = `${this.pricing.standardPlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.standardPlan.subtitle
        sellingProduct.price = this.pricing.standardPlan.monthlyPlan.perMonth
      } else if (item === 'premium') {
        sellingProduct.title = this.pricing.enterprisePlan.title
        sellingProduct.subtitle = this.pricing.enterprisePlan.subtitle
        sellingProduct.price = this.pricing.enterprisePlan.buildPrice
      } else if (item === 'premium_subscription') {
        sellingProduct.title = `${this.pricing.enterprisePlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.enterprisePlan.subtitle
        sellingProduct.price = this.pricing.enterprisePlan.monthlyPlan.perMonth
      }
      this.$store.dispatch('checkout/setSellingProduct', sellingProduct)
      this.$router.push({ name: 'checkout' })
    },
    refreshStripeLineItems(products) {
      this.stripeLineItems = []
      for (let i = 0; i < products.length; i += 1) {
        const product = products[i]
        if (!product.subscription) {
          if (product.qty * product.builds >= product.offerQtyLimit) {
            this.stripeLineItems.push({ price: product.stripe_product_discounted, quantity: product.qty })
          } else {
            this.stripeLineItems.push({ price: product.stripe_product, quantity: product.qty })
          }
        } else if (product.qty > product.offerQtyLimit) {
          this.stripeLineItems.push({ price: product.stripe_product_discounted, quantity: product.qty })
        } else {
          this.stripeLineItems.push({ price: product.stripe_product, quantity: product.qty })
        }
      }
    },
    calculatePrices(products) {
      this.totalDetails.total = this.calculateTotal(products).toFixed(2)
      this.totalDetails.discount = this.calculateDiscounts(products).toFixed(2)
      this.totalDetails.subTotal = (this.totalDetails.total + this.totalDetails.tax - this.totalDetails.discount).toFixed(2)
      this.totalDetails.totalQty = this.calculateTotalQty(products)
      this.refreshStripeLineItems(products)
    },
    calculateTotal(products) {
      let total = 0
      for (let i = 0; i < products.length; i += 1) {
        total += products[i].price
      }
      return total
    },
    calculateTotalQty(products) {
      let totalQty = 0
      for (let i = 0; i < products.length; i += 1) {
        totalQty += products[i].qty
      }
      return totalQty
    },
    calculateDiscounts(products) {
      let discounts = 0
      for (let i = 0; i < products.length; i += 1) {
        const product = products[i]
        if (!product.subscription) {
          if (product.qty * product.builds >= product.offerQtyLimit) {
            discounts += (Math.round(((product.price * product.discountPercentage) / 100) * 100) / 100)
          }
        } else if (product.qty > product.offerQtyLimit) {
          discounts += (Math.round(((product.price * product.discountPercentage) / 100) * 100) / 100)
        }
      }
      return discounts
    },
    createLocalOrderSignature(signature) {
      this.order_id = signature ? signature.split(',')[0] : ''
      this.signature_code = signature ? signature.split(',')[1] : ''
      this.$store.dispatch('checkout/setPaymentSignature', signature)
    },
    createNewOrder(paymentMethod, paymentStatus, paymentRef) {
      this.$store.dispatch('checkout/createNewOrder', {
        id: paymentRef, subtotal: this.totalDetails.total, total: this.totalDetails.subTotal, discount: this.totalDetails.discount, products: this.totalDetails.products, method: paymentMethod, status: paymentStatus, ref: paymentRef,
      }).then(id => {
        console.log(id)
      })
    },
    updateBuilds() {
      this.$store.dispatch('checkout/getSellingProduct').then(res => {
        this.builds.basicSubscription = 0
        this.builds.updateBuildsstandardSubscription = 0
        this.builds.premiumSubscription = 0
        this.totalDetails.products = []
        for (let i = 0; i < res.length; i += 1) {
          const product = {
            name: res[i].title,
            qty: res[i].qty,
            buildText: '3 Builds',
          }
          if (res[i].title.includes('Subscription')) {
            product.buildText = 'Unlimited Builds'
          }
          this.totalDetails.products.push(`${product.name} (${product.buildText}) x ${product.qty}`)
          const packageType = product.name.toLowerCase()
          if (packageType === 'basic') {
            this.builds.basic = product.qty
          } else if (packageType === 'basic subscription') {
            this.builds.basicSubscription = product.qty
          } else if (packageType === 'standard') {
            this.builds.standard = product.qty
          } else if (packageType === 'standard subscription') {
            this.builds.standardSubscription = product.qty
          } else if (packageType === 'premium') {
            this.builds.premium = product.qty
          } else if (packageType === 'premium subscription') {
            this.builds.premiumSubscription = product.qty
          }
        }
        this.$store.dispatch('checkout/updateUserBuilds', this.builds).then(id => {
          console.log(id)
          this.clearCart()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Payment Successful! Builds added.',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'home' })
        })
      })
    },
    clearCart() {
      this.$store.dispatch('checkout/clearAllSellingProducts')
    },
  },
  metaInfo: {
    // Overwrite the SEO title.
    title: 'Checkout',
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
